import React from 'react'
import Link from 'gatsby-link'
import {MDXRenderer} from "gatsby-plugin-mdx";
import {MDXProvider} from "@mdx-js/react";
import SLink from "./SLink";

const PostLink = ({ post, current }) => {
  var selectedClass;
  if (post.frontmatter.title == current) {
    selectedClass = "selected"
  };
  return (
    <div className={selectedClass}>
      <Link to={post.frontmatter.path}>
        {post.frontmatter.title}
      </Link>
    </div>
  )
}

export default class Wiki extends React.Component {

  render() {
    this.props.wikis.edges.sort((edge1, edge2) => edge1.node.frontmatter.title.length > edge2.node.frontmatter.title.length);
    const Posts = this.props.wikis.edges
      .filter(edge => !!edge.node.frontmatter.title && edge.node.frontmatter.level === "1") // You can filter your posts based on some criteria
      .map(edge => <PostLink key={edge.node.id} post={edge.node} current={this.props.post.frontmatter.title} />)
    var articleClass = "markdownWrapper " + this.props.post.frontmatter.style;
    return (
      <div>
        <div className="wiki-wrapper">
          <div className="topics-wrapper">{Posts}</div>
        </div>
        <article className={articleClass}>
            <h2
              style={{
                marginBottom: 0,
              }}
            >
              {this.props.post.frontmatter.title}
            </h2>
            <p
              style={{
                display: `block`,
                'width': '100%',
                'float': 'left',
              }}
            >
              {this.props.post.frontmatter.author ? <span>{this.props.post.frontmatter.author} &bull;</span> : null }
            </p>
            {this.props.post.frontmatter.note ?
              <p
                style={{
                  display: `block`,
                }}
                dangerouslySetInnerHTML={{'__html': this.props.post.frontmatter.note}}
              >
              </p>
              : null}
          <section className="mdxWrapper">
            <MDXProvider components={{
              "SLink": SLink,
            }}>
              <MDXRenderer >{this.props.mdx.body}</MDXRenderer>
            </MDXProvider>
          </section>
        </article>
      </div>
    )
  }
}